<template>
    <div>
        <v-sheet class="dense-inputs">
            <template v-if="!$vuetify.breakpoint.mobile">
                <v-row no-gutters>
                    <v-col class="d-flex align-center pt-3">
                        <v-autocomplete
                            :items="filterItems.country"
                            :loading="loading.filterItems.country"
                            :placeholder="$t('message.country')"
                            autocomplete="password"
                            class="filter-width-175 ml-0"
                            dense
                            hide-details="auto"
                            item-text="Country.name"
                            item-value="Country.id"
                            solo
                            v-model="filterValues.country"
                            @change="filterOtherOptions()"
                        />
                        <v-select
                            :items="filterItems.status"
                            :placeholder="$t('message.status')"
                            class="filter-width-225 ml-0"
                            hide-details="auto"
                            dense
                            solo
                            v-if="false"
                            v-model="filterValues.status"
                            @change="filterOtherOptions()"
                        />
                        <v-autocomplete
                            :items="filterItems.state"
                            :loading="loading.filterItems.state"
                            :placeholder="$t('message.state')"
                            autocomplete="password"
                            class="filter-width-175 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="State.name"
                            item-value="State.id"
                            solo
                            v-model="filterValues.state"
                            @change="fetchBuyerLocations()"
                        />
                        <v-autocomplete
                            :items="filterItems.city"
                            :loading="loading.filterItems.city"
                            :placeholder="$t('message.city')"
                            autocomplete="password"
                            class="filter-width-175 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="City.name"
                            item-value="City.id"
                            solo
                            v-model="filterValues.city"
                            @change="fetchBuyerLocations()"
                        />
                        <v-autocomplete
                            :items="filterItems.buyerLead"
                            :loading="loading.filterItems.buyerLead"
                            :placeholder="filterValues.showLeads ? $t('message.buyerLead') : $t('message.buyer')"
                            autocomplete="password"
                            class="filter-width-225 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="BuyerProspect.title"
                            item-value="BuyerProspect.id"
                            solo
                            v-model="filterValues.buyerLead"
                            @change="fetchBuyerLocations()"
                        >
                            <template v-slot:item="{ item }">
                                <div class="v-list-item__content v-list-item__title">
                                    <div class="d-flex flex-row justify-space-between">
                                        <span>{{ item.BuyerProspect.title }}</span>
                                        <div :class="'status-circle ' + statusCircles[item.BuyerProspect.status]">{{ item.BuyerProspect.status }}</div>
                                    </div>
                                </div>
                            </template>
                        </v-autocomplete>
                        <v-autocomplete
                            :items="filterItems.salesColleague"
                            :loading="loading.filterItems.salesColleague"
                            :placeholder="$t('message.salesColleague')"
                            autocomplete="password"
                            class="filter-width-190 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="SalesColleague.name"
                            item-value="SalesColleague.id"
                            solo
                            v-model="filterValues.salesColleague"
                            @change="fetchBuyerLocations()"
                        />
                        <v-autocomplete
                            :items="filterItems.supplier"
                            :loading="loading.filterItems.supplier"
                            :placeholder="$t('message.supplier')"
                            autocomplete="password"
                            class="filter-width-190 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Supplier.title"
                            item-value="Supplier.id"
                            solo
                            v-if="filterValues.showSuppliers == 1"
                            v-model="filterValues.supplier"
                            @change="fetchBuyerLocations()"
                        />
                        <v-btn
                            :loading="loading.buyers"
                            class="ml-2 px-2"
                            @click="fetchBuyerLocations()"
                            v-if="false"
                        >{{ $t('message.fetch') }}</v-btn>
                        <v-btn
                            :loading="loading.clear"
                            class="ml-2 px-2"
                            @click="clearFilters()"
                        >{{ $t('message.clear') }}</v-btn>
                        <v-spacer/>
                        <ExportTableJson
                            :export-conditions="filterValues"
                            :export-data="profiles"
                            :export-fields="exportFields"
                            :export-source="'buyer-locations'"
                            class="ml-3"
                            style="padding-top: 0px !important;"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="d-flex align-center">
                        <v-switch
                            :true-value="1"
                            :false-value="0"
                            :label="$t('message.showActive') + ' Prio 1'"
                            class="ml-0 font-sm"
                            dense
                            hide-details="auto"
                            v-model="filterValues.showActive"
                            @change="fetchBuyerLocations()"
                        />
                        <v-switch
                            :true-value="1"
                            :false-value="0"
                            :label="$t('message.showSleeping') + ' Prio 2'"
                            class="ml-4 font-sm"
                            dense
                            hide-details="auto"
                            v-model="filterValues.showSleeping"
                            @change="fetchBuyerLocations()"
                        />
                        <v-switch
                            :true-value="1"
                            :false-value="0"
                            :label="$t('message.showLeads') + ' Prio 3'"
                            class="ml-4 font-sm"
                            dense
                            hide-details="auto"
                            v-model="filterValues.showLeads"
                            @change="fetchBuyerLocations()"
                        />
                        <v-switch
                            :true-value="1"
                            :false-value="0"
                            :label="$t('message.showSuppliers')"
                            class="ml-2 font-sm"
                            dense
                            hide-details="auto"
                            v-model="filterValues.showSuppliers"
                            @change="fetchBuyerLocations('showSuppliers')"
                        />
                    </v-col>
                </v-row>
            </template>
        </v-sheet>
        <v-overlay
            :value="loading.buyers"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-row no-gutters class="pt-3">
            <v-col style="position: relative" class="d-flex flex-row">
                <template v-if="directionsPanel">
                    <v-slide-x-transition mode="out-in">
                        <v-card class="mr-2" :min-width="$vuetify.breakpoint.md ? '400px !important' : '600px !important'" :height="map.height + 'px'">
                            <v-card-title style="padding: 8px !important;">
                                <div class="d-flex flex-row align-center justify-space-between" style="width: 100%">
                                    <span>{{ $t('message.directions') }}</span>
                                    <v-btn icon @click="closeDirectionsPanel()" class="float-right">
                                        <v-icon class="black--text">close</v-icon>
                                    </v-btn>
                                </div>
                            </v-card-title>
                            <v-card-text class="px-0">
                                <v-row no-gutters class="pl-1 pr-3">
                                    <v-col>
                                        <draggable v-model="directionsRoute">
                                            <template v-for="(route, index) in directionsRoute">
                                                <div class="d-flex flex-row align-center" style="cursor: move" :key="route.id">
                                                    <div style="width: 25px !important;" class="d-flex flex-column justify-space-between">
                                                        <template v-if="index == 0">
                                                            <v-icon class="mdi mdi-circle-outline black--text lighten-1" small ></v-icon>
                                                            <v-icon class="mdi mdi-circle-small black--text lighten-1" small></v-icon>
                                                        </template>
                                                        <template v-if="index > 0 && index < directionsRoute.length - 1">
                                                            <v-icon class="mdi mdi-circle-small black--text lighten-1" small></v-icon>
                                                            <v-icon class="mdi mdi-circle-outline black--text lighten-1" small ></v-icon>
                                                            <v-icon class="mdi mdi-circle-small black--text lighten-1" small></v-icon>
                                                        </template>
                                                        <template v-if="index == directionsRoute.length - 1">
                                                            <v-icon class="mdi mdi-circle-small black--text lighten-1" small></v-icon>
                                                            <v-icon class="mdi mdi-map-marker-outline black--text lighten-1"></v-icon>
                                                        </template>
                                                    </div>
                                                    <div class="ml-5 v-input force-text-left v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed">
                                                        <div class="v-input__control">
                                                            <div class="v-input__slot">
                                                                <div class="v-text-field__slot">
                                                                    <GmapAutocomplete
                                                                        :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                                                        :ref="'RouteAutoComplete' + (index + 1).toString()"
                                                                        @place_changed="setLocation(route.id, $event)"
                                                                    ></GmapAutocomplete>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="width: 25px !important;" v-if="index > 1" class="ml-3">
                                                        <v-btn icon small @click="deleteRouteStop(index)"><v-icon class="black--text lighten-1 mdi mdi-delete-outline"></v-icon></v-btn>
                                                    </div>
                                                </div>
                                            </template>
                                        </draggable>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pl-1 pr-3">
                                    <v-col cols="12" v-if="directionsRoute[0]['lat'] == null">
                                        <v-btn text dense class="px-0 mx-0" @click="getUserLocation()">
                                            <v-icon class="mdi mdi-target success--text darken-1"></v-icon>
                                            <span class="ml-2">{{ $t('message.yourLocation') }}</span>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" class="d-flex flex-row justify-space-between">
                                        <v-btn text dense class="px-0 mx-0" @click="addRouteStop()">
                                            <v-icon class="mdi mdi-plus-circle-outline success--text darken-1"></v-icon>
                                            <span class="ml-2">{{ $t('message.addRouteStop') + ' ' + $t('message.maxNineStops') }}</span>
                                        </v-btn>
                                        <div>
                                            <v-tooltip bottom v-if="false">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="optimizeRoute()" icon><v-icon class="mdi mdi-directions-fork success--text darken-1"></v-icon></v-btn>
                                                </template>
                                                <span>{{ $t('message.optimizeRoute') }}</span>
                                            </v-tooltip>
                                            <template v-if="directionsRoute.filter(r => r.lat != null).length > 1">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" @click="printDirections()" icon><v-icon class="mdi mdi-printer success--text darken-1"></v-icon></v-btn>
                                                    </template>
                                                    <span>{{ $t('message.printDirections') }}</span>
                                                </v-tooltip>
                                            </template>
                                        </div>
                                    </v-col>
                                </v-row>
                                <vue-html2pdf
                                    :show-layout="true"
                                    :filename="'Route Directions'"
                                    :float-layout="false"
                                    :enable-download="false"
                                    :html-to-pdf-options="htmlToPdfOptions"
                                    :manual-pagination="false"
                                    :paginate-elements-by-height="1100"
                                    :pdf-quality="2"
                                    :preview-modal="false"
                                    pdf-format="a4"
                                    pdf-content-width="100%"
                                    pdf-orientation="portrait"
                                    ref="directionsHtml2Pdf"
                                    @beforeDownload="beforeDirectionsDownload($event)"
                                >
                                    <section slot="pdf-content">
                                        <v-app>
                                            <div id="textDirections" class="px-2 overflow-y-auto" :style="'border-top: 2px solid lightgray; height: ' +  (map.height - ((directionsRoute.length * 38) + 70))+ 'px'"></div>
                                        </v-app>
                                    </section>
                                </vue-html2pdf>
                            </v-card-text>
                        </v-card>
                    </v-slide-x-transition>
                </template>
                <vue-html2pdf
                    :show-layout="true"
                    :filename="'Buyer & Supplier Locations'"
                    :float-layout="false"
                    :enable-download="false"
                    :html-to-pdf-options="htmlToPdfOptions"
                    :manual-pagination="true"
                    :pdf-quality="2"
                    :preview-modal="false"
                    pdf-format="a4"
                    pdf-content-width="100%"
                    pdf-orientation="landscape"
                    ref="mapHtml2Pdf"
                    @beforeDownload="beforeMapDownload($event)"
                >
                    <section slot="pdf-content" :style="'width: ' + map.width + 'px; height:' + map.height + 'px'">
                        <v-app>
                            <GmapMap
                                :center="map.center"
                                :options="mapOptions"
                                :style="'width: 100%; height:' + map.height + 'px'"
                                ref="buyerLocations"
                                @zoom_changed="zoomChanged($event)"
                            >
                                <RouteDirectionsRenderer
                                    :route="directionsRoute"
                                    :optimize="optimizedRoute"
                                    travelMode="DRIVING"
                                />
                                <GmapCluster
                                    :zoomOnClick="true"
                                    :minimumClusterSize="5"
                                    :calculator="clusterCorrection"
                                >
                                    <GmapCustomMarker
                                        :marker="m.position"
                                        :offset-y="-35"
                                        :key="'gcm_' + index"
                                        v-for="(m, index) in customMarkers"
                                    >
                                        <div class="buyer-label">{{ m.profile.otsName }}</div>
                                    </GmapCustomMarker>
                                    <GmapMarker
                                        :key="index"
                                        :position="m.position"
                                        :clickable="true"
                                        :icon="markerImage(m.color, m.profile.type)"
                                        :label="markerLabel(m.profile.type, m.profile.status, m.color)"
                                        v-for="(m, index) in map.markers"
                                        @click="showByIndex = index"
                                        @dblclick="openDirectionsPanel($event)"
                                    >
                                        <GmapInfoWindow
                                            :opened="showByIndex === index"
                                            @closeclick="closeClick()"
                                        >
                                            <div style="max-width: 400px; width: 100%">
                                                <v-row dense no-gutters v-if="m.profile.type == 'prospect'" class="pb-2">
                                                    <v-col class="col-12">
                                                        <v-btn style="width: 100%" @click="openHubSpotProspect(m)" small>
                                                            <span class="font-weight-bold font-md">{{ $t('message.openHubSpotProspect') }}</span>
                                                            <v-icon class="mdi mdi-hubspot" style="color: #FF7A59 !important;"></v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row dense no-gutters v-if="m.profile.type == 'buyer' && m.profile.source_id != null" class="pb-2">
                                                    <v-col class="col-12">
                                                        <v-btn style="width: 100%" @click="openHubSpotProspect(m)" small>
                                                            <span class="font-weight-bold font-md">{{ $t('message.openHubSpotProspect') }}</span>
                                                            <v-icon class="mdi mdi-hubspot" style="color: #FF7A59 !important;"></v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row dense no-gutters v-if="m.profile.status != null">
                                                    <v-col class="col-4">{{ $t('message.status') }}</v-col>
                                                    <v-col :class="'font-sm font-weight-bold' + (m.profile.status == 'A' ? ' green--text text--darken-1' : ' red--text text--darken-1') ">{{ m.profile.status }}</v-col>
                                                </v-row>
                                                <v-row dense no-gutters>
                                                    <v-col class="col-4" v-if="m.profile.type == 'buyer'">{{ $t('message.buyer') }}</v-col>
                                                    <v-col class="col-4" v-if="m.profile.type == 'supplier'">{{ $t('message.supplier') }}</v-col>
                                                    <v-col class="col-4" v-if="m.profile.type == 'prospect'">{{ $t('message.prospect') }}</v-col>
                                                    <v-col class="font-sm font-weight-bold">{{ m.profile.name }}</v-col>
                                                </v-row>
                                                <v-row dense no-gutters>
                                                    <v-col class="col-4">{{ $t('message.salesColleague') }}</v-col>
                                                    <v-col class="font-sm font-weight-bold">{{ m.profile.salesColleague }}</v-col>
                                                </v-row>
                                                <template v-if="m.profile.type == 'prospect'">
                                                    <v-divider/>
                                                    <v-row dense no-gutters>
                                                        <v-col class="col-4">{{ $t('message.lastMeeting') }}</v-col>
                                                        <v-col class="font-sm font-weight-bold">
                                                            <span v-if="m.profile.lastMeeting != null">{{ formatDate( m.profile.lastMeeting ) }}</span>
                                                            <span v-else class="red--text darken-1">{{ $t('message.na') }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense no-gutters>
                                                        <v-col class="col-4">{{ $t('message.lastEmail') }}</v-col>
                                                        <v-col class="font-sm font-weight-bold">
                                                            <span v-if="m.profile.lastEmail != null">{{ formatDate( m.profile.lastEmail ) }}</span>
                                                            <span v-else class="red--text darken-1">{{ $t('message.na') }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense no-gutters>
                                                        <v-col class="col-4">{{ $t('message.lastNote') }}</v-col>
                                                        <v-col class="font-sm font-weight-bold">
                                                            <span v-if="m.profile.lastNote != null">{{ formatDate( m.profile.lastNote ) }}</span>
                                                            <span v-else class="red--text darken-1">{{ $t('message.na') }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                <template v-if="m.profile.type == 'buyer' && m.profile.source_id != null">
                                                    <v-divider/>
                                                    <v-row dense no-gutters>
                                                        <v-col class="col-4">{{ $t('message.lastMeeting') }}</v-col>
                                                        <v-col class="font-sm font-weight-bold">
                                                            <span v-if="m.profile.lastMeeting != null">{{ formatDate( m.profile.lastMeeting ) }}</span>
                                                            <span v-else class="red--text darken-1">{{ $t('message.na') }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense no-gutters>
                                                        <v-col class="col-4">{{ $t('message.lastEmail') }}</v-col>
                                                        <v-col class="font-sm font-weight-bold">
                                                            <span v-if="m.profile.lastEmail != null">{{ formatDate( m.profile.lastEmail ) }}</span>
                                                            <span v-else class="red--text darken-1">{{ $t('message.na') }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense no-gutters>
                                                        <v-col class="col-4">{{ $t('message.lastNote') }}</v-col>
                                                        <v-col class="font-sm font-weight-bold">
                                                            <span v-if="m.profile.lastNote != null">{{ formatDate( m.profile.lastNote ) }}</span>
                                                            <span v-else class="red--text darken-1">{{ $t('message.na') }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                <v-divider/>
                                                <v-row dense no-gutters>
                                                    <v-col class="col-4">{{ $t('message.streetAddress') }}</v-col>
                                                    <v-col class="font-sm font-weight-bold">{{ m.profile.streetAddress }}</v-col>
                                                </v-row>
                                                <v-row dense no-gutters>
                                                    <v-col class="col-4">{{ $t('message.city') }}</v-col>
                                                    <v-col class="font-sm font-weight-bold">{{ m.profile.city }}</v-col>
                                                </v-row>
                                                <v-row dense no-gutters>
                                                    <v-col class="col-4">{{ $t('message.state') }}</v-col>
                                                    <v-col class="font-sm font-weight-bold">{{ m.profile.state }}</v-col>
                                                </v-row>
                                                <v-row dense no-gutters>
                                                    <v-col class="col-4">{{ $t('message.postcode') }}</v-col>
                                                    <v-col class="font-sm font-weight-bold">{{ m.profile.postalCode }}</v-col>
                                                </v-row>
                                                <v-row dense no-gutters>
                                                    <v-col class="col-4">{{ $t('message.country') }}</v-col>
                                                    <v-col class="font-sm font-weight-bold">{{ m.profile.country }}</v-col>
                                                </v-row>
                                            </div>
                                        </GmapInfoWindow>
                                    </GmapMarker>
                                </GmapCluster>
                            </GmapMap>
                            <div class="legend-box" v-if="!$vuetify.breakpoint.mobile">
                                <template v-for="(salesColleague, index) in filterItems.salesColleague">
                                    <div :key="index" class="d-flex flex-row my-1 align-center">
                                        <div class="color-box" :style="'background-color: ' + salesColleague.SalesColleague.color"></div><span class="ml-2 font-weight-bold" style="font-size: 0.75rem !important;">{{ salesColleague.SalesColleague.name }}</span>
                                    </div>
                                </template>
                                <template>
                                    <div class="d-flex flex-row my-1 align-center">
                                        <div class="normal-box d-flex flex-row align-center justify-center">A</div><span class="ml-2 font-weight-bold" style="font-size: 0.75rem !important;">{{ $t('message.activeBuyer') }}</span>
                                    </div>
                                </template>
                                <template>
                                    <div class="d-flex flex-row my-1 align-center">
                                        <div class="normal-box d-flex flex-row align-center justify-center">S</div><span class="ml-2 font-weight-bold" style="font-size: 0.75rem !important;">{{ $t('message.sleepingBuyer') }}</span>
                                    </div>
                                </template>
                                <template>
                                    <div class="d-flex flex-row my-1 align-center">
                                        <div class="normal-box d-flex flex-row align-center justify-center">L</div><span class="ml-2 font-weight-bold" style="font-size: 0.75rem !important;">{{ $t('message.lead') }}</span>
                                    </div>
                                </template>
                                <template>
                                    <hr>
                                    <div class="d-flex flex-row my-1 align-center">
                                        <div class="color-box d-flex flex-row align-center justify-center" style="color: #000000; background-color: #FF00FF; font-size: 60%; font-weight: bold;">Spl</div><span class="ml-2 font-weight-bold" style="font-size: 0.75rem !important;">{{ $t('message.supplier') }}</span>
                                    </div>
                                </template>
                                <div class="d-flex flex-column">
                                    <v-btn small dark class="mt-2" @click="updateMapBounds()" >{{ $t('message.resetMap') }}</v-btn>
                                    <v-btn small dark class="mt-2" @click="printMap()" >{{ $t('message.printMap') }}</v-btn>
                                </div>
                            </div>
                        </v-app>
                    </section>
                </vue-html2pdf>
            </v-col>
            <template v-if="$vuetify.breakpoint.mobile">
                <div class="ml-1">
                    <v-btn icon small><v-icon class="mdi mdi-filter grey--text darken-1" @click="filterDialog = true"></v-icon></v-btn>
                </div>
                <v-dialog
                    v-model="filterDialog"
                >
                    <v-overlay
                        :value="loading.buyers"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-card>
                        <v-card-title class="justify-end">
                            <v-btn icon @click="filterDialog = false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex align-center">
                                    <v-autocomplete
                                        :items="filterItems.country"
                                        :loading="loading.filterItems.country"
                                        :placeholder="$t('message.country')"
                                        autocomplete="password"
                                        class="filter-width-175 ml-0"
                                        dense
                                        hide-details="auto"
                                        item-text="Country.name"
                                        item-value="Country.id"
                                        solo
                                        v-model="filterValues.country"
                                        @change="filterOtherOptions()"
                                    />
                                    <v-select
                                        :items="filterItems.status"
                                        :placeholder="$t('message.status')"
                                        class="filter-width-225 ml-0"
                                        hide-details="auto"
                                        dense
                                        solo
                                        v-if="false"
                                        v-model="filterValues.status"
                                        @change="filterOtherOptions()"
                                    />
                                    <v-autocomplete
                                        :items="filterItems.state"
                                        :loading="loading.filterItems.state"
                                        :placeholder="$t('message.state')"
                                        autocomplete="password"
                                        class="filter-width-175 ml-2"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="State.name"
                                        item-value="State.id"
                                        solo
                                        v-model="filterValues.state"
                                        @change="fetchBuyerLocations()"
                                    />
                                    <v-autocomplete
                                        :items="filterItems.city"
                                        :loading="loading.filterItems.city"
                                        :placeholder="$t('message.city')"
                                        autocomplete="password"
                                        class="filter-width-175 ml-2"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="City.name"
                                        item-value="City.id"
                                        solo
                                        v-model="filterValues.city"
                                        @change="fetchBuyerLocations()"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex align-center mt-3">
                                    <v-autocomplete
                                        :items="filterItems.buyerLead"
                                        :loading="loading.filterItems.buyerLead"
                                        :placeholder="filterValues.showLeads ? $t('message.buyerLead') : $t('message.buyer')"
                                        autocomplete="password"
                                        class="filter-width-225"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="BuyerProspect.title"
                                        item-value="BuyerProspect.id"
                                        solo
                                        v-model="filterValues.buyerLead"
                                        @change="fetchBuyerLocations()"
                                    >
                                        <template v-slot:item="{ item }">
                                            <div class="v-list-item__content v-list-item__title">
                                                <div class="d-flex flex-row justify-space-between">
                                                    <span>{{ item.BuyerProspect.title }}</span>
                                                    <div :class="'status-circle ' + statusCircles[item.BuyerProspect.status]">{{ item.BuyerProspect.status }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                    <v-autocomplete
                                        :items="filterItems.salesColleague"
                                        :loading="loading.filterItems.salesColleague"
                                        :placeholder="$t('message.salesColleague')"
                                        autocomplete="password"
                                        class="filter-width-190 ml-2"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="SalesColleague.name"
                                        item-value="SalesColleague.id"
                                        solo
                                        v-model="filterValues.salesColleague"
                                        @change="fetchBuyerLocations()"
                                    />
                                    <v-autocomplete
                                        :items="filterItems.supplier"
                                        :loading="loading.filterItems.supplier"
                                        :placeholder="$t('message.supplier')"
                                        autocomplete="password"
                                        class="filter-width-190 ml-2"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Supplier.title"
                                        item-value="Supplier.id"
                                        solo
                                        v-if="filterValues.showSuppliers == 1"
                                        v-model="filterValues.supplier"
                                        @change="fetchBuyerLocations()"
                                    />
                                    <v-btn
                                        :loading="loading.buyers"
                                        class="ml-2 px-2"
                                        @click="fetchBuyerLocations()"
                                        v-if="false"
                                    >{{ $t('message.fetch') }}</v-btn>
                                    <v-btn
                                        :loading="loading.clear"
                                        class="ml-2 px-2"
                                        @click="clearFilters()"
                                    >{{ $t('message.clear') }}</v-btn>
                                    <v-spacer/>
                                    <ExportTableJson
                                        :export-conditions="filterValues"
                                        :export-data="profiles"
                                        :export-fields="exportFields"
                                        :export-source="'buyer-locations'"
                                        class="ml-3"
                                        style="padding-top: 0px !important;"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="d-flex align-center">
                                    <v-switch
                                        :true-value="1"
                                        :false-value="0"
                                        :label="$t('message.showActive')"
                                        class="ml-0 font-sm"
                                        dense
                                        hide-details="auto"
                                        v-model="filterValues.showActive"
                                        @change="fetchBuyerLocations()"
                                    />
                                    <v-switch
                                        :true-value="1"
                                        :false-value="0"
                                        :label="$t('message.showSleeping')"
                                        class="ml-4 font-sm"
                                        dense
                                        hide-details="auto"
                                        v-model="filterValues.showSleeping"
                                        @change="fetchBuyerLocations()"
                                    />
                                    <v-switch
                                        :true-value="1"
                                        :false-value="0"
                                        :label="$t('message.showLeads')"
                                        class="ml-4 font-sm"
                                        dense
                                        hide-details="auto"
                                        v-model="filterValues.showLeads"
                                        @change="fetchBuyerLocations()"
                                    />
                                    <v-switch
                                        :true-value="1"
                                        :false-value="0"
                                        :label="$t('message.showSuppliers')"
                                        class="ml-2 font-sm"
                                        dense
                                        hide-details="auto"
                                        v-model="filterValues.showSuppliers"
                                        @change="fetchBuyerLocations('showSuppliers')"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
        </v-row>
    </div>
</template>

<script>
import MapStyle from "../../constants/MapStyle";
import { api } from "Api";
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import GmapCustomMarker from "vue2-gmap-custom-marker";
import VueHtml2pdf from 'vue-html2pdf';
import { formatDate, log } from "Helpers/helpers";
import RouteDirectionsRenderer from "Components/Appic/RouteDirectionsRenderer";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import { store } from "@/store/store";
import { mapFields } from "vuex-map-fields";
import { v4 as uuidv4 } from 'uuid';
import draggable from 'vuedraggable'

const ExportTableJson = () => import("Components/Appic/ExportTableJson");

Vue.use(VueGoogleMaps, {
    load: {
        key: store.state.appic.user.current.google ? store.state.appic.user.current.google.key : null,
        libraries: 'places',
    },
    installComponents: true
})

export default {
    name: "BuyerLocationsV2",
    components: { draggable, RouteDirectionsRenderer, ExportTableJson, GmapCluster, GmapCustomMarker, VueHtml2pdf },
    data(){
        return {
            currentZoom: 0,
            customMarkers: [],
            directions: null,
            directionsDestination: null,
            directionsRoute: [
                {id: uuidv4(), lat: null, lng: null},
                {id: uuidv4(), lat: null, lng: null}
            ],
            directionsOrigin: null,
            directionsPanel: false,
            // draggedRouteList: null,
            filterDialog: false,
            filterItems: {
                country: [],
                state: [],
                city: [],
                buyer: [],
                buyerLead: [],
                prospect: [],
                salesColleague: [],
                status: [
                    {value: 'ASP', text: 'Active, Sleeping & Prospect'},
                    {value: 'AS', text: 'Active & Sleeping'},
                    {value: 'A', text: 'Active'},
                    {value: 'S', text: 'Sleeping'},
                    {value: 'P', text: 'Prospect'}
                ],
                supplier: []
            },
            filterValues: {
                country: 1,
                state: null,
                city: null,
                buyer: null,
                buyerLead: null,
                prospect: null,
                salesColleague: null,
                status: 'ASP',
                supplier: null,
                showActive: 1,
                showLeads: 1,
                showSleeping: 1,
                showInactive: 1,
                showSuppliers: 0
            },
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.95
                },
                html2canvas: {
                    scale: 1.5
                }
            },
            loading: {
                filterItems: {
                    country: false,
                    state: false,
                    city: false,
                    buyer: false,
                    buyerLead: false,
                    prospect: false,
                    salesColleague: false,
                    supplier: false
                },
                clear: false,
                buyers: false
            },
            map: {
                center: {lat: 10, lng: 10},
                currentPlace: null,
                markers: [],
                places: [],
                height: 400,
                width: 600
            },
            optimizedRoute: false,
            // originAutoCompleteFocus: false,
            showByIndex: null,
            statusCircles: {
                A: 'active',
                S: 'sleeping',
                P: 'prospect'
            }
        }
    },
    computed: {
        ...mapFields('user',{
            User__country_id: 'current.User.country_id'
        }),
        exportFields() {
            return [
                {text: this.$t('message.type'), value: 'Profile.type'},
                {text: this.$t('message.status'), value: 'Profile.status'},
                {text: this.$t('message.name'), value: 'Profile.name'},
                {text: this.$t('message.salesColleague'), value: 'Profile.salescontact'},
                {text: this.$t('message.streetAddress'), value: 'Profile.address'},
                {text: this.$t('message.city'), value: 'Profile.city'},
                {text: this.$t('message.state'), value: 'Profile.state'},
                {text: this.$t('message.postcode'), value: 'Profile.postcode'},
                {text: this.$t('message.country'), value: 'Profile.country'}
            ]
        },
        profiles() {
            return this.map.markers
        },
        mapOptions() {
            const options = {
                styles: MapStyle.mapStyle
            }
            return options
        }
    },
    methods: {
        addRouteStop() {
            if(this.directionsRoute.length == 9){
                this.$toast.error(this.$t('message.maxNineStopsOnly'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            } else {
                this.directionsRoute.push({
                    id: uuidv4(),
                    lat: null,
                    lng: null
                })
            }
        },
        async beforeDirectionsDownload({ html2pdf, options, pdfContent }) {
            this.$toast(this.$t('message.successes.exportedDirectionsReadyMoment'), {
                classes: ['text--white'],
                color: 'info',
                timeout: 0,
                icon: 'check',
                iconColor: 'white',
                x: 'center',
                y: 'top'
            })
            const height = document.getElementById('textDirections').style.height
            document.getElementById('textDirections').classList.remove('overflow-y-auto')
            document.getElementById('textDirections').style.height = 'auto'
            let newOptions = options
            newOptions['margin'] = 5
            newOptions['pdf-content-width'] = null
            newOptions['html2canvas'] = { scale: 1, useCORS: true}
            await html2pdf().set(newOptions).from(pdfContent).save()
            let cmp = this.$toast.getCmp()
            cmp.close()
            document.getElementById('textDirections').classList.add('overflow-y-auto')
            document.getElementById('textDirections').style.setProperty('height', height )
        },
        async beforeMapDownload({ html2pdf, options, pdfContent }) {
            this.$toast(this.$t('message.successes.exportedMapReadyMoment'), {
                classes: ['text--white'],
                color: 'info',
                timeout: 0,
                icon: 'check',
                iconColor: 'white',
                x: 'center',
                y: 'top'
            })
            const height = this.$refs.buyerLocations.$el.clientHeight
            const width = this.$refs.buyerLocations.$el.clientWidth
            this.$refs.buyerLocations.$el.setAttribute('style','width: 1120px; height: 794px')
            let newOptions = options
            newOptions['margin'] = 5
            newOptions['pdf-content-width'] = null
            newOptions['html2canvas'] = { scale: 1, useCORS: true, scrollY: 0}
            newOptions['jsPDF'] = {
                format: 'a4',
                orientation: 'l'
            }
            await this.$nextTick(() => {
                html2pdf().set(newOptions).from(pdfContent).save()
                let cmp = this.$toast.getCmp()
                cmp.close()
                this.$refs.buyerLocations.$el.setAttribute('style','width: ' + width + 'px; height: ' + height + 'px')
            })
        },
        clearFilters() {
            if(this.User__country_id) {
                this.filterValues.country = this.User__country_id
            } else {
                this.filterValues.country = 1
            }
            this.filterValues.state = null
            this.filterValues.city = null
            this.filterValues.buyer = null
            this.filterValues.buyerLead = null
            this.filterValues.salesColleague = null
            this.filterValues.supplier = null
            this.filterValues.prospect = null
            this.filterValues.showActive = 1
            this.filterValues.showLeads = 1
            this.filterValues.showSleeping = 1
            this.filterValues.showInactive = 1
            this.filterValues.showSuppliers = 0
            this.fetchBuyerLocations()
        },
        closeClick() {
            this.showByIndex = null
        },
        closeDirectionsPanel() {
            this.directionsPanel = false
            this.directionsRoute = [
                {id: uuidv4(), lat: null, lng: null},
                {id: uuidv4(), lat: null, lng: null}
            ]
            this.optimizedRoute = false
            this.map.width = window.innerWidth - (95)
        },
        clusterCorrection(markers) {
            let markerCount = markers.length / 2;
            markerCount = Math.ceil(markerCount)

            return {
                text: markerCount,
                index: 0
            }
        },
        deleteRouteStop(index){
            this.directionsRoute.splice(index,1)
        },
        fetchBuyerLocations(event = null) {
            //check trigger
            if(event != null) {
                if (event === 'showSuppliers') {
                    this.customMarkers = [];
                    this.loadFilterItems('country')
                        .then(() => {
                            this.loadFilterItems('state')
                                .then(() => {
                                    this.loadFilterItems('city')
                                        .then(() => {
                                            this.loading.buyers = true
                                            this.filterBuyerLocations()
                                                .then(() => {
                                                    this.updateMapBounds()
                                                    this.loading.buyers = false
                                                })
                                                .catch(() => {
                                                    this.loading.buyers = false
                                                })
                                        })
                                        .catch((error) => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                })
                                .catch((error) => {
                                    this.$toast.error( error,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch((error) => {
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                } else if(event == 'showLeads') {
                    this.customMarkers = [];
                    this.loadFilterItems('buyerLead')
                        .then(() => {
                            this.loading.buyers = true
                            this.filterBuyerLocations()
                                .then(() => {
                                    this.updateMapBounds()
                                    this.loading.buyers = false
                                })
                                .catch(() => {
                                    this.loading.buyers = false
                                })
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t('message.error') + ': ' + error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            } else {
                this.loading.buyers = true
                this.customMarkers = []
                this.filterBuyerLocations()
                    .then(() => {
                        this.updateMapBounds()
                        this.loading.buyers = false
                    })
                    .catch(() => {
                        this.loading.buyers = false
                    })
            }
        },
        filterBuyerLocations() {
            return new Promise((resolve, reject) => {
                let conditions = []
                if(this.filterValues.country != null){
                    conditions.push({field: 'Company.country_id', value: this.filterValues.country})
                }
                if(this.filterValues.state != null){
                    conditions.push({field: 'Company.state', value: this.filterValues.state})
                }
                if(this.filterValues.city != null){
                    conditions.push({field: 'Company.city', value: this.filterValues.city})
                }
                if(this.filterValues.salesColleague != null){
                    conditions.push({field: 'Customer.salescontact_id', value: this.filterValues.salesColleague})
                }
                if(this.filterValues.supplier != null){
                    conditions.push({field: 'Supplier.id', value: this.filterValues.supplier})
                }
                if(this.filterValues.buyerLead != null){
                    conditions.push({field: 'BuyerProspect.id', value: this.filterValues.buyerLead})
                }
                if(this.filterValues.showActive != null){
                    conditions.push({field: 'show_active', value: this.filterValues.showActive})
                }
                if(this.filterValues.showSleeping != null){
                    conditions.push({field: 'show_sleeping', value: this.filterValues.showSleeping})
                }
                if(this.filterValues.showInactive != null){
                    conditions.push({field: 'show_inactive', value: this.filterValues.showInactive})
                }
                if(this.filterValues.showSuppliers != null){
                    conditions.push({field: 'show_suppliers', value: this.filterValues.showSuppliers})
                }
                if(this.filterValues.showLeads != null){
                    conditions.push({field: 'show_prospects', value: this.filterValues.showLeads})
                }
                api
                    .get('/customers/locations', {
                        params: {
                            conditions: conditions,
                        }
                    })
                    .then(response => {
                        if(response.data.status === 'success') {
                            this.map.markers = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                    })
                    .catch(error => {
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject(error)
                    })
            })
        },
        filterOtherOptions() {
            this.loading.buyers = true
            this.filterValues.state = null
            this.filterValues.city = null
            this.filterValues.buyer = null
            this.filterValues.buyerLead = null
            this.filterValues.salesColleague = null
            this.filterValues.supplier = null
            this.filterValues.prospect = null
            this.loadFilterItems('state')
                .then(() => {
                    this.loadFilterItems('city')
                        .then(() => {
                            this.loadFilterItems('buyerLead')
                                .then(() => {
                                    this.loadFilterItems('supplier')
                                        .then(() => {
                                            this.loadFilterItems('salesColleague')
                                                .then(() => {
                                                    this.fetchBuyerLocations()
                                                })
                                                .catch(() => this.loading.buyers = false)
                                        })
                                        .catch(() => this.loading.buyers = false)
                                })
                                .catch(() => this.loading.buyers = false)
                        })
                        .catch(() => this.loading.buyers = false)
                })
                .catch(() => this.loading.buyers = false)
        },
        formatDate,
        getUserLocation() {
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            id: uuidv4(),
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        const geocoder = new google.maps.Geocoder()
                        geocoder.geocode({'latLng': pos},(result, status) => {
                            if (status === google.maps.GeocoderStatus.OK) {
                                // this.$refs.originGmapAutocomplete.$refs.input.value = result[0].formatted_address
                                this.$nextTick(() => {
                                    this.$refs['RouteAutoComplete1'][0].$refs.input.value = result[0].formatted_address
                                })
                            }
                        })
                        this.$set(this.directionsRoute, 0, pos)
                    },
                    (err) => {
                        if(err.code == 1) {
                            const supportLink = 'https://support.google.com/maps/answer/2839911?hl=en&authuser=0&visit_id=637997532397766128-2554005372&co=GENIE.Platform%3DDesktop&oco=&p=browser_lp&rd=1#permission&zippy=%2Cchrome'
                            this.$toast.error(this.$t('message.errors.browserHasNoAccessToGeolocation'), {
                                classes: ['icon-float-left'],
                                icon: 'error_outline',
                                slot: [this.$createElement('v-btn', {
                                        color: 'success',
                                        class: 'small float-right',
                                        on: {
                                            click: () => {
                                                window.open(supportLink, '_blank')
                                            }
                                        }
                                    }, this.$t('message.pleaseRead'))
                                ],
                                dismissable: true,
                                timeout: 10000
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.cannotDetermineYourLocation'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                );
            } else {
                this.$toast.error(this.$t('message.errors.browserNotSupportGeolocation'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        handleResize() {
            this.map.height = window.innerHeight - (this.$vuetify.breakpoint.mobile ? 75 : 200);
            if(this.directionsPanel) {
                this.map.width = window.innerWidth - (95 + (this.$vuetify.breakpoint.md ? 410 : 610))
            } else {
                this.map.width = window.innerWidth - (95)
            }
        },
        loadFilterItems(filter) {
            return new Promise((resolve, reject) => {
                if(filter && filter.length > 0) {
                    this.loading.filterItems[filter] = true
                    if(['state','city','buyer','salesColleague','supplier'].includes(filter)) {
                        let conditions = []
                        let obj = {
                            field: 'companies.country_id',
                            value: this.filterValues.country
                        }
                        conditions.push(obj)
                        if (this.filterValues.showSuppliers != null) {
                            let showSupplierObj = {
                                field: 'show_suppliers',
                                value: this.filterValues.showSuppliers
                            }
                            conditions.push(showSupplierObj)
                        }
                        api
                            .get('/customers/filter-options/' + filter, {
                                params: {
                                    conditions: conditions
                                }
                            })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else if(filter === 'country') {
                        let conditions = []
                        if (this.filterValues.showSuppliers != null) {
                            let showSupplierObj = {
                                field: 'show_suppliers',
                                value: this.filterValues.showSuppliers
                            }
                            conditions.push(showSupplierObj)
                        }
                        api
                            .get('/customers/filter-options/' + filter, {
                                params: {
                                    conditions: conditions
                                }
                            })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else if(filter === 'prospect') {
                        let conditions = []
                        let obj = {
                            field: 'prospects.country_id',
                            value: this.filterValues.country
                        }
                        conditions.push(obj)
                        api
                            .get('/customers/filter-options/' + filter, {
                                params: {
                                    conditions: conditions
                                }
                            })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else if(filter === 'buyerLead') {
                        let conditions = []
                        let obj = {
                            field: 'countries.id',
                            value: this.filterValues.country
                        }
                        conditions.push(obj)
                        if (this.filterValues.showLeads != null) {
                            let showProspectObj = {
                                field: 'show_prospects',
                                value: this.filterValues.showLeads
                            }
                            conditions.push(showProspectObj)
                        }
                        api
                            .get('/customers/filter-options/' + filter, {
                                params: {
                                    conditions: conditions
                                }
                            })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        api
                            .get('/customers/filter-options/' + filter)
                            .then(response => {
                                if (response.data.status === 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    }
                } else {
                    reject('Filter empty')
                }
            })
        },
        markerImage (pinColor = "red", type = "buyer") {
            // let pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
            let pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z"
            // let labelOriginHole = new google.maps.Point(12,15);
            let labelOriginFilled = new google.maps.Point(12,9);
            return {
                // path: type == 'prospect' ? pinSVGFilled : pinSVGHole,
                path: pinSVGFilled,
                anchor: new google.maps.Point(12,17),
                fillOpacity: 1,
                fillColor: pinColor,
                strokeWeight: 2,
                strokeColor: "white",
                scale: 2,
                labelOrigin: labelOriginFilled,
                // labelOrigin: type == 'prospect' ? labelOriginFilled : labelOriginHole,
            };
        },
        markerLabel(type = "buyer", status = "active", pinColor = null) {
            if(type == "prospect") {
                let color = '#000000'
                if(pinColor != null && ['#FF0000','#008000','#0000FF'].includes(pinColor)) color = '#FFFFFF'
                return {text: 'L', fontWeight: "bold", color: color}
            }

            if(type == "supplier") return {text: 'Spl', fontWeight: "normal", color: "#FFFFFF", size: '11px'}

            if(type == "buyer"){
                let color = '#000000'
                if(pinColor != null && ['#FF0000','#008000','#0000FF'].includes(pinColor)) color = '#FFFFFF'

                if(status == "A") return {text: 'A', fontWeight: "bold", color: color}
                if(status == "S") return {text: 'S', fontWeight: "bold", color: color}
                if(status == "L") return {text: 'L', fontWeight: "bold", color: color}
            }
            return null
        },
        openDirectionsPanel(marker) {
            this.map.width = window.innerWidth - (95 + (this.$vuetify.breakpoint.md ? 410 : 610))
            this.directionsPanel = true
            this.setDestination(marker)
            //zoom on marker
            this.$refs.buyerLocations.$mapPromise.then((map) => {
                map.panTo(marker.latLng)
                map.setZoom(12)
            })
        },
        openHubSpotProspect(prospect) {
            window.open(
                process.env.VUE_APP_HUBSPOT
                + '/contacts/'
                + prospect.profile.main_contact_id
                + '/company/'
                + prospect.profile.source_id
                , "_blank")
        },
        openLocationsFilter() {
            this.filterDialog = true
        },
        optimizeRoute() {
            this.optimizedRoute = true
        },
        printDirections() {
            this.$refs.directionsHtml2Pdf.generatePdf()
        },
        printMap() {
            this.$refs.mapHtml2Pdf.generatePdf()
        },
        setDestination( marker ) {
            let latLng = marker.latLng
            const geocoder = new google.maps.Geocoder()
            geocoder.geocode({'latLng': latLng},(result, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.$nextTick(() => {
                        this.$refs['RouteAutoComplete' + (this.directionsRoute.length).toString()][0].$refs.input.value = result[0].formatted_address
                    })
                }
            })
            const pos = {
                id: uuidv4(),
                lat: marker.latLng.lat(),
                lng: marker.latLng.lng()
            }
            if(this.directionsRoute.length == 0){
                this.directionsRoute.push(pos)
            } else {
                this.$set(this.directionsRoute, this.directionsRoute.length - 1, pos)
            }
        },
        setLocation(uuid ,location) {
            if(location.geometry) {
                const pos = {
                    id: uuid,
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng()
                }
                const index = this.directionsRoute.findIndex(d => d.id === uuid )
                this.$set(this.directionsRoute, index, pos)
            } else {
                this.$set(this.directionsRoute, index, null)
            }
        },
        updateDirectionsMapBounds() {
            if(this.directionsRoute[0] && this.directionsRoute[this.directionsRoute.length - 1]) {
                const bounds = new google.maps.LatLngBounds()
                bounds.extend(this.directionsRoute[0])
                bounds.extend(this.directionsRoute[this.directionsRoute.length - 1])
                map.fitBounds(bounds);
                map.panToBounds(bounds)
            }
        },
        updateMapBounds() {
            this.$refs.buyerLocations.$mapPromise.then((map) => {
                const bounds = new google.maps.LatLngBounds()
                for (let marker of this.map.markers) {
                    bounds.extend(marker.position)
                }
                map.fitBounds(bounds);
                map.panToBounds(bounds)
                this.customMarkers = this.map.markers
            });
            return true
        },
        zoomChanged(zoom) {
            this.currentZoom = zoom
        }
    },
    created() {
        if(this.User__country_id) this.filterValues.country = this.User__country_id

        window.addEventListener('resize', this.handleResize)
        window.addEventListener("resize", this.updateMapBounds);

        if(this.filterItems.country.length == 0) this.loadFilterItems('country')
        if(this.filterItems.state.length == 0) this.loadFilterItems('state')
        if(this.filterItems.city.length == 0) this.loadFilterItems('city')
        // if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
        if(this.filterItems.salesColleague.length == 0) this.loadFilterItems('salesColleague')
        if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
        // if(this.filterItems.prospect.length == 0) this.loadFilterItems('prospect')
        if(this.filterItems.buyerLead.length == 0) this.loadFilterItems('buyerLead')

        this.filterBuyerLocations()
            .then(() => {
                this.updateMapBounds()
            })
    },
    mounted(){
        this.handleResize()
        this.updateMapBounds()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener("resize", this.updateMapBounds);
    }
}
</script>

<style scoped>
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-190 {
    width: 190px !important;
    max-width: 190px !important;
}
.filter-width-225 {
    width: 225px !important;
    max-width: 225px !important;
}
.legend-box {
    background-color: white;
    padding: 10px;
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 10;
    opacity: 0.8;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
}
.color-box {
    width: 20px;
    height: 20px;
    border: 1px solid lightgray;
}
.normal-box {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    color: #000000;
    font-weight: bold;
}
.buyer-label {
    font-size: 1.2em;
    font-weight: bold;
    padding: 2px 3px;
    background-color: white;
    border: 1px solid black;
}
.status-circle {
    display: inline-block;
    line-height: 1rem;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border: 2px solid grey;
    border-radius: 50%;
}
.status-circle.active {
    border-color: #3C6932;
}
.status-circle.sleeping {
    border-color: orangered;
}
.status-circle.prospect {
    border-color: darkgrey;
}
::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
    background: lightgray;        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid lightgray;
}
.html2canvas-container {
    width: 3000px !important;
    height: 3000px !important;
}
</style>