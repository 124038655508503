import {MapElementFactory} from 'vue2-google-maps';

export default MapElementFactory({
	name: 'routeDirectionsRenderer',
	ctr: () => google.maps.DirectionsRenderer,
	events: ['directions_changed'],
	mappedProps: {
		routeIndex: {type: Number},
		options: {type: Object},
		panel: {},
		directions: {type: Object, twoWay: true},
	},
	props: {
		route: { type: Array },
		optimizeWaypoints: { type: Boolean },
		travelMode: { type: String }
	},
	beforeCreate (options) {},
	afterCreate (directionsRendererInstance) {
		let directionsService = new google.maps.DirectionsService();
		this.$watch(
			() => [this.route, this.optimizeWaypoints, this.travelMode],
			() => {
				directionsRendererInstance.set('directions', null);
				directionsRendererInstance.setPanel(document.getElementById('textDirections'));
				let { route, optimizeWaypoints, travelMode } = this;
				if (!route || !travelMode || route.length <= 1) {
					return;
				} else {
					const origin = route[0]
					let destination = route[route.length - 1]
					let waypointsCtr = route.length - 2
					if(destination.lat == null){
						//get real destination
						for(let i = route.length - 1; i >= 1; i--){
							if(route[i].lat != null){
								destination = route[i]
								waypointsCtr -= i
								break
							}
						}
					}
					let waypoints = []
					for(let i = 1; i <= waypointsCtr; i++){
						if(route[i].lat != null) {
							waypoints.push({
								location: route[i],
								stopover: true
							})
						}
					}
					if(origin.lat != null && destination.lat != null) {
						directionsService.route(
							{
								origin,
								destination,
								waypoints,
								travelMode,
								optimizeWaypoints,
								drivingOptions: {
									departureTime: new Date(Date.now())
								}
							},
							(response, status) => {
								if (status !== "OK") return;
								directionsRendererInstance.setDirections(response);
							}
						);
					}
				};
			},
			{ deep: true }
		);
	},
})